import { useEffect, useState } from 'react';

const useRandomIncrement = ({
	initialValue = 0,
	interval = 2000,
	minIncrement = 10000,
	maxIncrement = 30000
}) => {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		const intervalId = setInterval(() => {
			// Generate a random increment within the specified range
			const randomIncrement =
				Math.floor(Math.random() * (maxIncrement - minIncrement + 1)) + minIncrement;
			setValue((prevValue) => prevValue + randomIncrement);
		}, interval);

		// Clear the interval on unmount
		return () => clearInterval(intervalId);
	}, [interval, minIncrement, maxIncrement]);

	return value;
};

export { useRandomIncrement };
