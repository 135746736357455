import React, { useEffect, useState } from 'react';
import BrandConfigStore from '@/context/store/brand-config';
import walletUtils from '../../../../utils/wallet.utils';
import { useRandomIncrement } from '@/hooks/useUnaryOperator.hook';
// import { useBroadcastChannel } from '@/hooks/useBroadcastChannel.hook';

const JackpotProgressComponent = () => {
	const { config } = BrandConfigStore((state) => ({
		config: state.config
	}));

	const { pagesSetting } = config;
	const initialJackpotValue = pagesSetting?.home?.components?.jackpot?.totalJackpot;

	const newJackpotTotalPrice = useRandomIncrement({
		initialValue: initialJackpotValue
	});

	const [jackpotTotalPrice, setJackpotTotalPrice] = useState(initialJackpotValue);

	useEffect(() => {
		setJackpotTotalPrice(newJackpotTotalPrice);
	}, [newJackpotTotalPrice]);

	return (
		<React.Fragment>
			<div className="flex justify-center w-full mb-4">
				{/* <img
					src="/images/jackpot-progress/jackpot-progress3-mobile.gif"
					alt=""
					className="w-full h-full"
				/>
				<p className="absolute top-0 bottom-0 right-[5%] flex items-center justify-start text-white font-bold bg-inherit leading-none">
					<span className="gradient-text-color text-sm mobile-s:text-xs">
						{pagesSetting?.home?.components?.jackpot?.currency ?? 'IDR'}{' '}
						{walletUtils.formatCurrency(jackpotTotalPrice)}
					</span>
				</p> */}

				<div className="hidden lg:flex relative w-full h-full">
					<img
						src="/images/jackpot-progress/jackpot-progress-desktop.gif"
						alt=""
						className="w-full h-full"
					/>
					<p className="absolute top-[17%] md:top-[32%] lg:top-[30%] right-[4%] left-0 flex items-end justify-end text-white font-bold bg-inherit leading-none">
						<span className="gradient-text-color text-sm md:text-3xl lg:text-4xl">
							{pagesSetting?.home?.components?.jackpot?.currency ?? 'IDR'}{' '}
							{walletUtils.formatCurrency(jackpotTotalPrice)}
						</span>
					</p>
				</div>

				<div className="lg:hidden relative w-full h-full">
					<img
						src="/images/jackpot-progress/jackpot-progress-mobile.gif"
						alt=""
						className="w-full h-full"
					/>

					<p className="absolute top-[28%] sm:top-[30%] md:top-[32%] lg:top-[30%] right-[4%] left-0 flex items-end justify-end text-white font-bold bg-inherit leading-none">
						<span className="gradient-text-color text-xl sm:text-2xl md:text-4xl lg:text-7xl">
							{pagesSetting?.home?.components?.jackpot?.currency ?? 'IDR'}{' '}
							{walletUtils.formatCurrency(jackpotTotalPrice)}
						</span>
					</p>
				</div>
			</div>
		</React.Fragment>
	);
};

export default JackpotProgressComponent;
